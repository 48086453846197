import { Center, Stack, Title } from '@liveeo/component-library';
import { Button } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export const TemporaryPageNotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Center h="100vh">
      <Stack>
        <Title>Page not found.</Title>
        <Button onClick={() => navigate('/')}>Back to safety</Button>
      </Stack>
    </Center>
  );
};
