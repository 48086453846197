import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePlots, useResetParams, useUser } from '../../hooks';
import {
  Button,
  Grid,
  Stack,
  Title,
  Text,
  Image,
} from '@liveeo/component-library';
import {
  deforestationDisturbance,
  deforestationRiskMapping,
} from '../../helper';
import SUPPLIERS_ICON from '../../../assets/suppliers-icon.svg';
import classes from './CollapsedRow.module.css';

type Props = {
  riskScorePrecision: number | undefined;
  riskScoreOpenSource: number | undefined;
  riskScore: number | undefined;
  supplierName: string;
  supplierId: string | null;
  plotId: string;
  isSupplierView: boolean;
  status?: string;
};

const DeforestationResults = ({
  riskScorePrecision,
  riskScoreOpenSource,
  status,
}: Partial<Props>) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid.Col span={4}>
        <Stack>
          <Text ta="center">
            {status !== 'PENDING' && typeof riskScorePrecision === 'undefined'
              ? '--'
              : t(`analysis.deforestation`, {
                  percentage: deforestationDisturbance(riskScorePrecision),
                })}
          </Text>
          <Text ta="center">
            {typeof riskScoreOpenSource === 'undefined'
              ? '--'
              : t(`analysis.disturbance`, {
                  percentage: deforestationDisturbance(riskScoreOpenSource),
                })}
          </Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={4}>
        <Stack gap={typeof riskScorePrecision !== 'number' ? 10 : undefined}>
          {typeof riskScorePrecision !== 'number' ? (
            <Button
              size="compact-sm"
              to="https://live-eo.com/tradeaware-contact"
              component={Link}
              target="_blank"
              rel="noopener"
            >
              {t<string>('analysis.specialist')}
            </Button>
          ) : (
            <Text
              ta="center"
              className={classes.text}
              data-hidden={typeof riskScorePrecision === 'undefined'}
            >
              {t(
                `common.status.${deforestationRiskMapping(riskScorePrecision)}`
              )}
            </Text>
          )}
          <Text
            className={classes.text}
            data-hidden={typeof riskScoreOpenSource === 'undefined'}
          >
            {t(
              `common.status.${deforestationRiskMapping(riskScoreOpenSource)}`
            )}
          </Text>
        </Stack>
      </Grid.Col>
    </>
  );
};

export const CollapsedRow = ({
  riskScorePrecision,
  riskScoreOpenSource,
  supplierName,
  supplierId,
  plotId,
  isSupplierView,
}: Omit<Props, 'riskScore'>) => {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const { resetParams } = useResetParams();
  const { findPlotById } = usePlots();
  const plot = findPlotById(plotId);
  return supplierId && plotId ? (
    <Stack className={classes.root}>
      <Text size="sm" fs="italic">
        {t('analysis.title')}
      </Text>
      <Grid grow>
        <Grid.Col span={4}>
          <Stack>
            <Title order={6}>{t('analysis.layers.precision')}</Title>
            <Title order={6}>{t('analysis.layers.global')}</Title>
          </Stack>
        </Grid.Col>
        <DeforestationResults
          riskScorePrecision={riskScorePrecision}
          riskScoreOpenSource={riskScoreOpenSource}
          status={plot?.mostRecentAnalysesByType?.PRECISION?.status}
        />
      </Grid>
      {!isSupplierView && supplierId !== user?.businessId && (
        <Button
          leftSection={<Image src={SUPPLIERS_ICON} w={15} />}
          className={classes.button}
          component={Link}
          to={`../suppliers/${supplierId}?${resetParams()}&sel=${plotId}`}
        >
          {supplierName}
        </Button>
      )}
    </Stack>
  ) : null;
};
