import { Center, Group, Stack, Text, Title } from '@liveeo/component-library';
import { Button } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import { useSurvey } from '../../hooks/useSurvey';
import { useSurveyJsModel } from '../../hooks/useSurveyJsModel';
import { useSurveyResponse } from '../../hooks/useSurveyResponse';
import { TemporaryLoadingPage } from './TemporaryLoadingPage';
import { TemporaryPageNotFoundPage } from './TemporaryPageNotFoundPage';

export const EditSurveyResponsePage = () => {
  const { surveyResponseId } = useParams();
  const { data: surveyResponse, isLoading: surveyResponseIsLoading } =
    useSurveyResponse(surveyResponseId);
  const navigate = useNavigate();
  const { data: survey, isLoading: surveyLoading } = useSurvey(
    surveyResponse?.surveyId
  );
  const surveyModel = useSurveyJsModel(survey?.json, surveyResponse?.json);
  const save = () => {
    // TODO TEUDR-1695 implement update
    // const surveyResponseJson = surveyModel?.data;
    // Note: this will navigate away
    // updateSurveyResponse({
    //   json: surveyResponseJson,
    //   countryCode: surveyResponse?.countryCode,
    // });
  };

  const cancel = () => {
    // TODO TEUDR-1695 we probably want to alert the user of any unsaved changes
    navigate('/map/documents'); // TODO TEUDR-1695 the documents table panel is not opened on this navigation
  };

  if (surveyResponseIsLoading || surveyLoading) return <TemporaryLoadingPage />;
  if (!survey || !surveyResponse) return <TemporaryPageNotFoundPage />;
  if (!surveyModel) return <TemporaryLoadingPage />;

  // TODO TEUDR-1695 make this look nice
  // TODO TEUDR-1695 extract translations
  // TODO TEUDR-1695 avoid duplication with CreateSurveyResponsePage
  // TODO TEUDR-1695 UX: should country selection still be visible and editable?
  return (
    <Center>
      <Stack w="100vw">
        <Stack
          w="100vw"
          maw={'calc(90*(var(--sjs-base-unit, var(--base-unit, 8px))))'}
          mx={'auto'}
        >
          <Title ta="center" order={1} size="h2">
            {survey.name}
          </Title>
          <Text fs={'italic'}>
            Please answer the following questions regarding the risk assessment
            for your country of production.
          </Text>
          <Survey model={surveyModel} />
        </Stack>
        <Group justify="flex-end" p="md">
          <Button variant="outline" w="175px" onClick={cancel}>
            Close
          </Button>
          <Button variant="outline" w="175px" onClick={save}>
            Save & close
          </Button>
          <Button variant="outline" w="175px">
            Add another country
          </Button>
          <Button variant="outline" w="175px">
            Submit
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
