import { Button, Stack, Text, Title } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { heightOfSidePanelContent } from '../../helper/side-panel-height';
import { useToggleAside } from '../../hooks';
import { useSurveyResponses } from '../../hooks/useSurveyResponses';
import { useSurveys } from '../../hooks/useSurveys';
import { Table } from '../../shared/components/Table';
import { SidePanelContainer } from './SidePanelContainer';

const EmptyTable = () => {
  const { t } = useTranslation();
  const { toggleAside } = useToggleAside();

  return (
    <Table.EmptyState>
      <Stack align="center" w="63%" gap={20}>
        <Stack gap={0}>
          <Title ta="center" order={4}>
            {/* TODO TEUDR-1695 add to localise */}
            {t('documents.table.empty.title')}
          </Title>
          <Text ta="center">{t('documents.table.empty.text')}</Text>
          <Button>{t('documents.empty.add')}</Button>
        </Stack>
      </Stack>
      <Table.EmptyState.Footer handleClose={() => toggleAside(undefined)} />
    </Table.EmptyState>
  );
};

// TODO TEUDR-1695 this is just copied from the table panel. implement the actual table.
export const DocumentsTablePanel = () => {
  const { t } = useTranslation();
  // const { trackEvent } = useTracking();
  const { data: surveys, isLoading: isLoadingSurveys } = useSurveys();
  const { data: surveyResponses, isLoading: isLoadingSurveysResponses } =
    useSurveyResponses();
  const isEmpty = !isLoadingSurveysResponses && !surveyResponses?.length;
  const navigate = useNavigate();

  //TODO TEUDR-1695 add for Mix Panel when ready
  // useEffect(() => {
  //   trackEvent('Buyer', {
  //     step: 'buyer-page',
  //     action: 'open',
  //   });
  // }, [trackEvent]);

  return (
    <SidePanelContainer>
      <SidePanelContainer.Header>
        <SidePanelContainer.Title
          // TODO TEUDR-1695 add to localise
          title={t<string>('documents.table.title')}
        />
      </SidePanelContainer.Header>

      {isLoadingSurveysResponses ? (
        <Table.Loader height={heightOfSidePanelContent} />
      ) : isEmpty ? (
        <EmptyTable />
      ) : (
        <Stack>
          {(surveyResponses ?? []).map((sr) => (
            <Text key={sr.id}>
              {sr.surveyId} {sr.countryCode} {sr.status} {sr.id}
            </Text>
          ))}
          <Text>Feature Filled Documents table coming soon.</Text>
          <Button
            onClick={() => {
              if (isLoadingSurveys || !surveys) return;
              navigate(`/documents/new/${surveys[0]?.id}`);
            }}
          >
            Add survey response
          </Button>
        </Stack>
      )}
    </SidePanelContainer>
  );
};
