import { useSurveys } from './useSurveys';

export const useSurvey = (surveyId?: string) => {
  // TODO TEUDR-1695 missing endpoint to get a single survey, so we just reuse the endpoint to get all surveys
  const { data: surveys, isFetching, isLoading } = useSurveys();
  const data = surveys?.find((s) => s.id === surveyId) ?? null;

  return {
    data,
    isLoading,
    isFetching,
  };
};
