import { useQuery } from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import { Survey } from '../shared/types';

export const useSurveys = () => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['surveys'],
    queryFn: () => fetch('surveys') as Promise<Survey[]>,
    // queryFn: fakeSurveyFetch,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};

// const partialCmsJson = {
//   title: 'LiveEO/CMS Risk Assessment',
//   logoPosition: 'right',
//   showTitle: false,
//   pages: [
//     {
//       name: 'Risk Assessment',
//       // title: 'LiveEO/CMS Risk Assessment',
//       navigationTitle: 'Risk Assessment',
//       elements: [
//         {
//           type: 'radiogroup',
//           name: 'riskAssignmentOfCountry',
//           title:
//             'The assignment of risk to the relevant country of production or parts thereof in accordance with Article 29',
//           choices: [
//             {
//               value: 'high',
//               text: 'High',
//             },
//             {
//               value: 'standard',
//               text: 'Standard',
//             },
//             {
//               value: 'low',
//               text: 'Low',
//             },
//           ],
//         },
//         {
//           type: 'boolean',
//           name: 'presenceOfForest',
//           title:
//             'Is there a presence of forests in the country of production or parts thereof?',
//           startWithNewLine: true,
//           renderAs: 'radio',
//           swapOrder: true,
//         },
//       ],
//     },
//   ],
// };
// // TODO TEUDR-1695 remove - curently useful for development without backend
// const fakeSurveyFetch = async (): Promise<Survey[]> => {
//   return [
//     {
//       id: 'survey-12345678',
//       createdAt: '',
//       name: 'LiveEO/CMS Risk Assessment Mock data',
//       json: partialCmsJson,
//       ownerId: null,
//       updatedAt: '',
//     },
//   ];
// };
