import {
  Beta,
  Group,
  HoverCard,
  Pill,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from '@liveeo/component-library';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@mantine/hooks';
import classes from './Legend.module.css';
import {
  useLayerState,
  usePlots,
  useTableState,
  useToggleAside,
} from '../../hooks';
import { hasAnalysis } from '../../helper';
import { LAYER_COLORS } from '../../common/constants';

type Props = {
  color: string;
  isActive: boolean;
};

const LayerIndicator = styled.div<Props>`
  width: 12px;
  height: 12px;
  background-color: ${({ color, isActive }) =>
    isActive ? color : 'transparent'};
  border: ${({ color, isActive }) =>
    isActive ? `1px solid ${color}` : '1px solid #ddd'};
  cursor: pointer;
`;

export const Legend = () => {
  const { t } = useTranslation();
  const [isLegendOpen, toggleLegend] = useToggle();
  const { layerState, setLayerState } = useLayerState();
  const { tableState } = useTableState();
  const { isAsideOpen } = useToggleAside();
  const { findPlotById } = usePlots();
  const plot = findPlotById(tableState.sel || '');

  return (
    <div className={classes.legend} data-open={isAsideOpen}>
      {isLegendOpen && (
        <Stack className={classes.content}>
          <Title order={5}>{t<string>('layerToggle.title')}</Title>
          <Group justify="space-between">
            <UnstyledButton
              disabled={!hasAnalysis('PRECISION', plot)}
              onClick={() =>
                setLayerState({ precision: !layerState.precision })
              }
            >
              <Group>
                <LayerIndicator
                  color={LAYER_COLORS.precision}
                  isActive={!!layerState.precision}
                />
                <Text
                  className={classes['layer-name']}
                  data-selected={!!layerState.precision}
                >
                  {t<string>('layerToggle.precision.name')}
                </Text>
              </Group>
            </UnstyledButton>
            {!hasAnalysis('PRECISION', plot) && (
              <UnstyledButton
                component={Link}
                to="https://www.live-eo.com/contact"
                target="_blank"
                rel="noopener"
              >
                <Pill variant="outline" radius="xs" size="xs">
                  {t<string>('common.upgrade')}
                </Pill>
              </UnstyledButton>
            )}
          </Group>
          <HoverCard position="top" openDelay={1500}>
            <HoverCard.Target>
              <UnstyledButton
                onClick={() => setLayerState({ gFC2020: !layerState.gFC2020 })}
              >
                <Group>
                  <LayerIndicator
                    color={LAYER_COLORS.openSource}
                    isActive={!!layerState.gFC2020}
                  />
                  <Text
                    className={classes['layer-name']}
                    data-selected={layerState.gFC2020}
                  >
                    {t<string>('layerToggle.globalForestWatch.name')}
                  </Text>
                </Group>
              </UnstyledButton>
            </HoverCard.Target>
            <HoverCard.Dropdown className={classes.tooltip}>
              <Stack>
                <Text>
                  {t<string>('layerToggle.globalForestWatch.link.text')}
                </Text>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  to="https://data.jrc.ec.europa.eu/dataset/10d1b337-b7d1-4938-a048-686c8185b290"
                >
                  <Text>
                    {t<string>('layerToggle.globalForestWatch.link.button')}
                  </Text>
                </Link>
              </Stack>
            </HoverCard.Dropdown>
          </HoverCard>
        </Stack>
      )}
      <UnstyledButton onClick={() => toggleLegend()}>
        <Group gap={10}>
          <Beta.Icon icon={isLegendOpen ? 'minimize2' : 'maximize2'} />
          <Text>
            {isLegendOpen ? `${t('legend.hide')}` : `${t('legend.show')}`}
          </Text>
        </Group>
      </UnstyledButton>
    </div>
  );
};
