import styled from '@emotion/styled';
import '@emotion/react';

import type { MantineTheme, MantineThemeOverride } from '@mantine/core';

// re-export mantine and emotion functions to have a single api and to make
// typescript take the theme overrides into account.
export { styled };
export type { MantineTheme };

/**
 * Color Definitions
 *
 * All definitions are taken from the design system:
 * @See https://www.figma.com/file/AVqSfZmJuX9vdQBrV1GmnB/Design-System?type=design&node-id=0-1&mode=design&t=q4fO66ztHamA1l0c-0
 */
export const colors = {
  white: '#FCFCFC',
  pureWhite: '#FFFFFF',
  black: '#1F1D22',
  black100: '#F4F2F6',
  black200: '#EBE8ED',
  black300: '#D7D3DA',
  black400: '#C4C0C8',
  black500: '#AFABB4',
  black600: '#8F8A95',
  black700: '#1F1D22',
  black800: '#48434E',
  black900: '#353138',
  red: '#D4111B',
  red70: '#E1585F',
  red10: '#FBEBE9',
  green: '#03A14F',
  green70: '#4FBD84',
  green10: '#D3F5E3',
  purple: '#5A2F80',
  purple50: '#F8F0FF',
  purple100: '#F1E0FF',
  purple200: '#CBB4DF',
  purple300: '#A688C0',
  purple400: '#805BA0',
  purple500: '#5A2F80',
  purple600: '#411667',
  purple700: '#27004D',
  yellow: '#FFAB1A',
  yellow70: '#FFC45F',
  yellow10: '#FCEACA',
  blue: '#4E80E1',
  blue70: '#83A6EA',
  blue10: '#DEE7F8',
  orange: '#ED7E11',
  transparent: '#00000000',
} as const;

export type Colors = typeof colors;

export const font = {
  family:
    "Inter, 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  weight: {
    headings: 600,
    text: {
      normal: 300,
    },
  },
  color: {
    text: colors.white,
  },
} as const;

/**
 * Legacy Theme Definition
 */
export const defaultTheme = {
  colors: {
    ...colors,
    primary: colors.green,
    secondary: colors.purple,
    secondary100: colors.purple100,
    secondary200: colors.purple200,
    secondary300: colors.purple300,
    secondary400: colors.purple400,
    secondary600: colors.purple600,
    secondary700: colors.purple700,
    error: colors.red,
    errorLight: colors.red10,
    warning: colors.yellow,
    success: colors.green,
    text: colors.black,
    darkGrey: colors.black700,
    disableLight: colors.black200,
    secondaryWhite: colors.black200,
    gray: colors.black400,
    lightGray3: colors.black200,
    secondaryGray: colors.black300,

    // These colors aren't defined in the design system
    // @See https://www.figma.com/file/AVqSfZmJuX9vdQBrV1GmnB/Design-System?type=design&node-id=0-1&mode=design&t=F7JV65k8l0UuwzYC-0
    secondary0: '#DDD9E1',
    label: '#4e4b66',
    placeholder: '#bcbfd9',
    background: colors.black,
  },
  spacing: {
    borderRadiusMedium: 0.125,
  },
  animation: {
    durationShort: 0.125,
  },
  font: {
    family:
      "Metropolis, 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    sizes: {
      small: 0.875,
      regular: 1,
    },
  },
};

export const mantineTheme: MantineThemeOverride = {
  fontFamily:
    "Inter, 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  white: colors.white,
  black: colors.black,
  primaryColor: 'purple',
  primaryShade: 4,
  defaultRadius: 'xs',
  shadows: {
    sm: '0px 2px 8px -2px rgba(0, 0, 0, 0.25)',
  },
  headings: {
    fontWeight: '600',
    fontFamily:
      "Inter, 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  colors: {
    green: [
      colors.green10,
      colors.green70,
      colors.green,
      colors.green,
      colors.green,
      colors.green,
      colors.green,
      colors.green,
      colors.green,
      colors.green,
    ],
    purple: [
      colors.purple50,
      colors.purple100,
      colors.purple200,
      colors.purple300,
      colors.purple400,
      colors.purple500,
      colors.purple600,
      colors.purple700,
      colors.purple700,
      colors.purple,
    ],
    dark: [
      colors.pureWhite,
      colors.black100,
      colors.black200,
      colors.black300,
      colors.black400,
      colors.black500,
      colors.black600,
      colors.black700,
      colors.black800,
      colors.black900,
    ],
    red: [
      colors.red10,
      colors.red70,
      colors.red,
      colors.red,
      colors.red,
      colors.red,
      colors.red,
      colors.red,
      colors.red,
      colors.red,
    ],
    orange: [
      colors.yellow10,
      colors.yellow70,
      colors.yellow,
      colors.yellow,
      colors.yellow,
      colors.yellow,
      colors.yellow,
      colors.yellow,
      colors.yellow,
      colors.yellow,
    ],
  },
};
