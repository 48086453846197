import {
  Center,
  Text,
  Beta,
  Flex,
  Icon,
  Stack,
  Anchor,
  UnstyledButton,
  LoadingSpinner,
} from '@liveeo/component-library';
import { useBusiness, useUser } from '../../../hooks';
import classes from '../Admin.module.css';
import { GenericErrorBoundary } from '../../../shared/components/GenericErrorBoundary';
import { useTranslation } from 'react-i18next';
import { ShowAndCopyClientId } from './ShowAndCopyClientId';
import { ShowClientSecret } from './ShowClientSecret';
import { RotateSecret } from './RotateSecret';
import { CreateClientModal } from './CreateClientModal';
import { GenericErrorFallback } from '../../../shared/components/GenericErrorFallback';
import { useNavigate } from 'react-router-dom';

export const DevTools = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: user, isError } = useUser();
  const { data: business, isLoading } = useBusiness(user?.businessId);
  const apiDocs =
    'https://docs.live-eo.com/tradeaware/user-guides/authentication';

  return isLoading ? (
    <Center w="100%" h="100vh">
      <LoadingSpinner />
    </Center>
  ) : isError || !business || !user ? (
    <GenericErrorFallback />
  ) : (
    business &&
    user && (
      <Center w="100%" h="100vh" mih="675px">
        <Flex direction="column" gap="md" className={classes.container}>
          <Stack m="lg">
            <Flex align="center">
              <UnstyledButton
                variant="unstyled"
                onClick={() => navigate('/admin')}
              >
                <Icon icon="arrow-left" color="#fff" size="lg" />
              </UnstyledButton>
              <Text size="xl" ml="md" fw="bolder">
                {t<string>('devTools.title')}
              </Text>
            </Flex>
            <Text fs="italic">{t<string>('devTools.text')}</Text>
            <GenericErrorBoundary title={t<string>('developerTools.error')}>
              <ShowAndCopyClientId businessId={user?.businessId} />
              <ShowClientSecret clientId={business?.machineToMachineClientId} />
            </GenericErrorBoundary>
            <Flex justify="space-between" align="center">
              <Text fs="italic">
                {t<string>('devTools.apiText')}{' '}
                <Anchor
                  target="_blank"
                  underline="hover"
                  href={apiDocs}
                  className={classes.link}
                  rel="noopener"
                >
                  {t<string>('devTools.apiLink')}
                </Anchor>
              </Text>
              <Anchor
                target="_blank"
                underline="hover"
                href={`${apiDocs}`}
                c="white"
                className={classes.iconLink}
                rel="noopener"
              >
                <Beta.Icon icon="external-link" />
              </Anchor>
            </Flex>
          </Stack>
          <RotateSecret clientId={business?.machineToMachineClientId} />
        </Flex>
        <CreateClientModal businessId={user.businessId} />
      </Center>
    )
  );
};
