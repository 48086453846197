import {
  Center,
  Group,
  Select,
  Stack,
  Text,
  Title,
} from '@liveeo/component-library';
import { Button } from '@mantine/core';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';
import { countryList } from '../../../assets/countryList';
import { useSurvey } from '../../hooks/useSurvey';
import { useSurveyJsModel } from '../../hooks/useSurveyJsModel';
import { useSurveyResponses } from '../../hooks/useSurveyResponses';
import { TemporaryLoadingPage } from './TemporaryLoadingPage';
import { TemporaryPageNotFoundPage } from './TemporaryPageNotFoundPage';

export const CreateSurveyResponsePage = () => {
  const { surveyId } = useParams();
  const { data: survey, isLoading } = useSurvey(surveyId);
  const navigate = useNavigate();
  const { createSurveyResponse } = useSurveyResponses(surveyId);
  const [country, setCountry] = useState<string | null>(null);
  const surveyModel = useSurveyJsModel(survey?.json);

  const save = () => {
    if (!country || !surveyModel) return;
    const surveyResponseJson = surveyModel.data;
    // Note: this will navigate away on success
    createSurveyResponse.execute({
      json: surveyResponseJson,
      countryCode: country,
    });
  };

  const cancel = () => {
    // TODO TEUDR-1695 we probably want to alert the user of any unsaved changes
    navigate('/map/documents'); // TODO TEUDR-1695 the documents table panel is not opened on this navigation
  };

  if (isLoading || !surveyModel) return <TemporaryLoadingPage />;
  if (!survey) return <TemporaryPageNotFoundPage />;
  if (!surveyModel) return <TemporaryLoadingPage />;

  // TODO TEUDR-1695 make this look nice
  // TODO TEUDR-1695 extract translations
  return (
    <Center>
      <Stack w="100vw">
        <Stack
          w="100vw"
          maw={'calc(90*(var(--sjs-base-unit, var(--base-unit, 8px))))'}
          mx={'auto'}
        >
          <Title ta="center" order={1} size="h2">
            {survey.name}
          </Title>
          <Text fs={'italic'}>
            Please answer the following questions regarding the risk assessment
            for your country of production.
          </Text>
          <Select
            label="Country"
            placeholder="Country"
            data={countryList}
            searchable
            onChange={setCountry}
            styles={{
              root: { backgroundColor: 'var(--mantine-color-body)' },
              wrapper: { backgroundColor: 'var(--mantine-color-body)' },
              input: { backgroundColor: 'var(--mantine-color-body)' },
              dropdown: { backgroundColor: 'var(--mantine-color-body)' },
            }}
          />
          <Survey model={surveyModel} />
        </Stack>

        <Group justify="flex-end" p="md">
          <Button variant="outline" w="175px" onClick={cancel}>
            Close
          </Button>
          <Button
            variant="outline"
            w="175px"
            onClick={save}
            disabled={!country}
            loading={createSurveyResponse.isLoading}
          >
            Save & close
          </Button>
          <Button
            variant="outline"
            w="175px"
            disabled={!country}
            loading={createSurveyResponse.isLoading}
          >
            Add another country
          </Button>
          <Button
            variant="outline"
            w="175px"
            disabled={!country}
            loading={createSurveyResponse.isLoading}
          >
            Submit
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
