import { useQuery } from '@tanstack/react-query';
import { SurveyResponse } from '../shared/types';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';

export const useSurveyResponse = (surveyResponseId?: string) => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['survey-response', surveyResponseId],
    queryFn: () =>
      fetch(`survey-responses/${surveyResponseId}`) as Promise<SurveyResponse>,
    enabled: !!surveyResponseId,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isLoading,
    isFetching,
  };
};

// TODO TEUDR-1695 remove. currently useful for development without backend
// const fakeSurveyResponseFetch = async (): Promise<SurveyResponse> => {
//   return {
//     id: 'sr-12345678',
//     countryCode: 'DE',
//     respondentId: '12345678',
//     status: 'DRAFT',
//     surveyId: '1234567',
//     json: {},
//     updatedAt: '2024-11-22T14:07:58Z',
//     createdAt: '2024-11-22T14:07:58Z',
//   };
// };
