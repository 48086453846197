import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { CreateSurveyResponseDto, SurveyResponse } from '../shared/types';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';

export const useSurveyResponses = (surveyId?: string) => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError, successfullyCreated } = useNotification();
  const navigate = useNavigate();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['survey-responses'],
    // TODO TEUDR-1695 GET /survey-responses is still missing on the backend
    // queryFn: () => fetch('survey-responses') as Promise<SurveyResponse[]>,
    queryFn: fakeSurveyResponsesFetch,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const createMutation = useMutation({
    mutationKey: ['survey-responses'],
    mutationFn: (payload: CreateSurveyResponseDto) =>
      fetch(`surveys/${surveyId}/survey-responses`, {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
  });

  const createSurveyResponse = (payload: CreateSurveyResponseDto) =>
    createMutation.mutate(payload, {
      onSuccess: (surveyResponse_: any) => {
        const surveyResponse = surveyResponse_ as SurveyResponse;
        successfullyCreated('survey response');
        queryClient.setQueryData(
          ['survey-responses'],
          (prev: SurveyResponse[] | undefined) =>
            prev && [surveyResponse, ...prev]
        );
        queryClient.setQueryData(['survey-response'], surveyResponse);
        queryClient.invalidateQueries({ queryKey: ['survey-responses'] });
        navigate(
          {
            pathname: `/documents/${surveyResponse.id}`,
          },
          { replace: true }
        );
      },
      onError: apiError,
    });

  return {
    data,
    isLoading,
    isFetching,
    createSurveyResponse: {
      execute: createSurveyResponse,
      isLoading: createMutation.isLoading,
    },
  };
};

// TODO TEUDR-1695 remove. currently useful for development without backend
const fakeSurveyResponsesFetch = async (): Promise<SurveyResponse[]> => {
  return [
    {
      id: 'sr-12345678',
      countryCode: 'DE',
      respondentId: '12345678',
      status: 'DRAFT',
      surveyId: '1234567',
      json: {},
      createdAt: '2024-11-22T14:07:58Z',
      updatedAt: '2024-11-22T14:07:58Z',
    },
  ];
};
