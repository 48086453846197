import { colors } from './theme';

/** created using https://surveyjs.io/form-library/documentation/manage-default-themes-and-styles#:~:text=ContrastLight)%3B-,Create%20a%20Custom%20Theme,-SurveyJS%20themes%20use */
export const surveyJsThemeDarkBorderlessPanelless = {
  themeName: 'borderless',
  colorPalette: 'dark',
  isPanelless: true,
  backgroundImage: '',
  backgroundOpacity: 1,
  backgroundImageAttachment: 'scroll',
  backgroundImageFit: 'cover',
  cssVariables: {
    '--sjs-font-editorfont-family': 'var(--mantine-font-family)',
    '--sjs-font-editorfont-color': 'var(--text-color)',
    '--sjs-font-pagetitle-color': 'var(--text-color)',
    '--sjs-font-pagetitle-family': 'var(--mantine-font-family)',
    '--sjs-font-questiontitle-color': 'var(--text-color)',
    '--sjs-font-questiontitle-family': 'var(--mantine-font-family)',
    '--sjs-general-backcolor-dark': 'rgba(50, 55, 72, 1)',
    '--sjs-general-backcolor-dim-dark': 'rgba(40, 46, 61, 1)',
    '--sjs-general-backcolor-dim-light': 'rgba(36, 41, 55, 1)',
    '--sjs-general-backcolor-dim': colors.transparent,
    '--sjs-general-backcolor': 'rgba(43, 48, 63, 1)',
    '--sjs-general-forecolor-light': colors.white,
    '--sjs-general-forecolor': 'rgba(255, 255, 255, 0.78)',
    '--sjs-primary-backcolor': colors.white,
    '--sjs-primary-forecolor': 'rgba(32, 32, 32, 1)',
    '--sjs-default-font-family': 'var(--mantine-font-family)',
  },
  headerView: 'basic',
} as const;
